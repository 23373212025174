import { ArrowRightIcon } from '@heroicons/react/outline';
import React from 'react';

import { SimpleDialog } from '@/components/Elements';
import { useAuth } from '@/lib/auth';

export const MergerBanner = () => {
  const { user } = useAuth();

  return (
    <SimpleDialog
      title={
        <p className="font-inter text-base leading-[26px] tracking-normal">
          Hi <span className="font-semibold">{user?.data.firstName}</span> —
        </p>
      }
      triggerButton={
        <button className="flex cursor-pointer items-center gap-1">
          Via will be acquired by Justworks! <ArrowRightIcon className="h-4 w-4" />
        </button>
      }
    >
      <div className="space-y-[18px] font-inter text-base leading-[26px] tracking-normal">
        <p>
          We’re excited to share that Via has entered into a definitive merger agreement to be
          acquired by{' '}
          <a
            href="https://www.justworks.com"
            target="_blank"
            className="text-dodger-blue-600 underline underline-offset-[3px]"
            rel="noreferrer"
          >
            Justworks
          </a>
          .
        </p>
        <p>
          Justworks is a technology company that levels the playing field for all small businesses.
          Through its software and as a partner, Justworks helps its customers take care of their
          teams, streamline their operations, and navigate the complex aspects of managing a
          workforce with confidence.
        </p>
        <p>
          Since Via’s founding in 2017, we’ve been focused on helping companies like yours hire and
          pay international teams. Like Via, Justworks is committed to making it easier to start,
          run, and join a global business with its platform.
        </p>
        <p>
          We look forward to joining forces with the Justworks team, where we’ll continue building a
          local-first international employment platform. Together, we’ll push Via’s original mission
          even further and elevate Justworks’ all-in-one offering for small businesses and
          entrepreneurs. You can read more about the acquisition{' '}
          <a
            href="https://www.justworks.com/blog/acquiring-via-to-accelerate-our-local-vision-for-global-teams"
            target="_blank"
            className="text-dodger-blue-600 underline underline-offset-[3px]"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>
          To the extent that your agreement with Via requires notice in connection with the
          contemplated transactions under the merger agreement, this note constitutes such notice.
        </p>
        <p>
          <span className="font-medium">What’s next?</span> <br />
          At this time, there will be no changes to your everyday use of Via or any of the other
          services we provide. We’ll be working closely with the Justworks team to ensure business
          continuity and you can expect to hear from us regarding any important updates along the
          way.
        </p>
        <p>
          After six years of building, we’re excited about this next chapter for Via as part of the
          Justworks family. If you have any questions at all, please feel free to contact us at{' '}
          <a
            href="mailto:support@via.work"
            className="text-dodger-blue-600 underline underline-offset-[3px]"
          >
            support@via.work
          </a>
          .
        </p>
        <p className="font-semibold">The Via team</p>
      </div>
    </SimpleDialog>
  );
};

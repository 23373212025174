import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

import { FieldWrapper } from '@/components/Form/FieldWrapper';

type Option = {
  label: string;
  value: string;
};

type SelectYearProps = {
  name: string;
};

const options: Option[] = [
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2020',
    value: '2020',
  },
];

export const SelectYear = ({ name }: SelectYearProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValue = searchParams.get(`filter[${name}]`) || undefined;

  return (
    <FieldWrapper label="Select report year">
      <select
        className={clsx(
          'block w-full appearance-none',
          'rounded-3xl border border-gray-300',
          'px-8 py-2 capitalize shadow-sm',
          'focus:border-dodger-blue-600 focus:ring-dodger-blue-600',
          'truncate sm:text-sm'
        )}
        translate="no"
        onChange={(event) => setSearchParams({ 'filter[year]': event.currentTarget.value })}
        defaultValue={defaultValue}
      >
        {options.map(({ label, value }) => (
          <option key={label} value={value}>
            {label}
          </option>
        ))}
      </select>
    </FieldWrapper>
  );
};
